import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Token from "@/helpers/user/user.js";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./state/store";
import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from "maska";
import { createPinia } from "pinia";

import VueFeather from "vue-feather";

import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

import "@/assets/scss/config/default/app.scss";
import "@vueform/slider/themes/default.css";
import "@/assets/scss/mermaid.min.css";

const pinia = createPinia();

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

async function initApp() {
  try {

    const { getUser } = Token();
    await getUser();
  } catch (err) {
    console.error(err);
  }
  createApp(App)
    .use(PrimeVue, {
      theme: {
        preset: Aura
      }
    })
    .use(store)
    .use(pinia)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside)
    .mount("#app");
}
initApp();
