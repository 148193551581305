import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { ref } from "vue"
const TIMECLOSE = 3000;

export const successToast = (title) => {
  toast.success(title, {
    theme: "colored",
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: TIMECLOSE,
  });
};

export const errorToast = (title) => {
  toast.error(title, {
    theme: "colored",
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: TIMECLOSE,
  });
};

export const warningToast = (title) => {
  toast.warning(title, {
    theme: "colored",
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: TIMECLOSE,
  });
};


const toastId = ref("");
export const loadingToast = (text) =>
(toastId.value = toast.loading(text, {
  autoClose: false,
  transition: toast.TRANSITIONS.SLIDE,
  position: toast.POSITION.BOTTOM_RIGHT,
  icon: false,
}));
export const closeLoadingToast = () => {
  toast.remove(toastId.value);
};